import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

    <Layout>
        <section className="section-home">

            <div id="root">
                <div className="ae af">


                    <main id="main">
                        <div
                            className="wcb3-billboard-cta b5 b6 b7 b8 b9 ba bb ae bx be jg bg bh bi bj c0 bl ji bn c2 bp jk br">
                            <div className="bj bs bt">
                                <h1>About Us</h1>
                                <h3> BellMe</h3>
                                <h3>Who We Are and What We Do Best</h3><br/>
                                <h3>Search, Compare and Book Any Type Vehicle,</h3>
                                <h3> in Any City, for Any Number of Pax, and</h3>
                                <h3> at the Lowest Available Rate!</h3>
                            </div>
                            <br/>
                            <div className="bj bs bt">
                                <p>BellMe will never send just “anybody” to pick you or your group up like other
                                    TNCs!</p>
                                <p>We’re “Your Personal Driver Everywhere.” </p>
                                <p>Our loyal and vetted drivers will ensure your 100% satisfaction! We have many
                                    clients, drivers, and fleet operators that have been with us since we opened our
                                    doors in 2001. </p>
                                <p>Cheap, fast, good. Pick two. Lyft and Uber try to be all three. Impossible!!! You’ve
                                    read the headlines. Neither are ALWAYS cheap, fast, good, or SAFE. Most people I
                                    have spoken to have and continue to use them, have told me there was an issue either
                                    with the driver or the vehicle that was sent; drivers that didn’t look appealing and
                                    cars that were too small, late, cancelled, lost, and dirty or they were charged
                                    higher rates do to surge pricing.</p>
                                <p>Those same people have told me they would use someone else if the choice was
                                    available.</p>
                                <p>Why have so many of our drivers and clients been with us for almost two decades?
                                    Because we respect our drivers clients alike, and we promote safety, quality,
                                    consistency, and value.</p>
                                <p>We’re not a technology company. We’re not tech guys. I’m the founder and a limo
                                    driver with over 30 years of customer service. We know what drivers want and we know
                                    what clients expect and demand.</p>
                                <p>It’s all about Trust and Customer Service, regardless the price.</p>
                                <p>We are not a TNC, we’re an old fashioned global reservation and dispatch network with
                                    over 17 years on the Internet and 30 years of transportation experience. We’re a
                                    digital advertising platform for the ground transportation industry for safe,
                                    licensed, quality minded, independent owner operators including: Taxi, Black Car,
                                    Limo, Gig (Lyft/Uber driver), and large fleet operators.</p>
                                <p>You can search and find EVERY type vehicle on our Quotebot: sedans, crossovers,
                                    stretch limos, SUVs, Sprinter vans, minibuses, trolleys, and motor coaches. You can
                                    even book a wheelchair accessible or armored (w/ bodyguard) vehicle.</p>
                                <p>No other website does what BellMe can do?</p>
                                <p>When you book through us, you’ll save time and money. You’re our client and we’ll
                                    guarantee your complete satisfaction. We’re responsible for the booking and you’ll
                                    get the best customer support from start to finish. When you find the vehicle(s) you
                                    like, you’ll be booking directly (through us) with that specific owner operator or
                                    fleet operator and at their lowest available rate. We will always have a driver and
                                    vehicle for you, it’s just a matter of which lowest rate is available.</p>

                                <p>We’ll dollar cost average you in every city and never Surge Price you!</p>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    </Layout>
);

export default Page;
